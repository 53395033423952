var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column"},[_c('h1',{staticClass:"title"},[_c('b-icon',{attrs:{"icon":_vm.singleInsurance.icon}}),_vm._v(" "+_vm._s(_vm.singleInsurance.name)+" ")],1),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"box"},[_c('h1',{staticClass:"subtitle"},[_vm._v(" Verzekeraar: ")]),_c('div',{staticClass:"column has-text-centered"},[_c('img',{staticStyle:{"width":"8rem"},attrs:{"src":require('@/assets/insurer_logo_' + _vm.index + '.png'),"alt":"Insurer logo"}})])])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"box"},[_c('h1',{staticClass:"subtitle"},[_vm._v(" Premie: ")]),_c('h1',{staticClass:"title has-text-centered"},[_vm._v(" "+_vm._s(_vm.singleInsurance.auction.lowestBid)+" €12.30 ")]),_c('h1',{staticClass:"subtitle has-text-centered"},[_vm._v(" per maand ")])])])]),_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column is-1"},[_vm._v(" 22/03/2021 ")]),_c('div',{staticClass:"column is-10"},[_c('b-progress',{attrs:{"value":75,"type":"is-link","format":"percent","show-value":""}},[_vm._v(" Nog 27 dagen tot einde looptijd ")])],1),_c('div',{staticClass:"column is-1"},[_vm._v(" 22/03/2022 ")])])])]),_c('div',{staticClass:"column is-6"},[_c('div',{staticClass:"box"},[(_vm.singleInsurance.coverageChoices)?_c('h1',{staticClass:"subtitle"},[_vm._v(" Mijn keuze: ")]):_vm._e(),(_vm.singleInsurance.coverageChoices)?_c('div',{staticClass:"columns is-multiline"},_vm._l((_vm.singleInsurance.coverageChoices.filter(function (coverage) {
              return (
                coverage.advice
              );
            })),function(coverage){return _c('div',{key:coverage.id,staticClass:"column"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"column has-text-centered"},[_c('b-tooltip',{attrs:{"label":coverage.description}},[_c('b-icon',{attrs:{"size":"is-large","type":"is-light","icon":coverage.icon}})],1)],1),_c('div',{staticClass:"column has-text-centered has-text-light"},[_vm._v(" "+_vm._s(coverage.name)+" ")])])])}),0):_vm._e(),(_vm.singleInsurance.coverageOptions)?_c('h1',{staticClass:"subtitle"},[_vm._v(" Mijn dekking: ")]):_vm._e(),(_vm.singleInsurance.coverageOptions)?_c('div',{staticClass:"columns is-multiline"},_vm._l((_vm.singleInsurance.coverageOptions.filter(function (coverage) {
              return (
                coverage.advice
              );
            })),function(coverage){return _c('div',{key:coverage.id,staticClass:"column"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"column has-text-centered"},[_c('b-tooltip',{attrs:{"label":coverage.description}},[_c('b-icon',{attrs:{"size":"is-large","type":"is-light","icon":coverage.icon}})],1)],1),_c('div',{staticClass:"column has-text-centered has-text-light"},[_vm._v(" "+_vm._s(coverage.name)+" ")])])])}),0):_vm._e(),_c('b-button',{attrs:{"expanded":"","type":"is-primary","icon-left":"car-crash"}},[_vm._v(" Meld schade ")])],1)]),_c('div',{staticClass:"column is-6"},[_c('div',{staticClass:"box"},[_c('h1',{staticClass:"subtitle"},[_vm._v(" Veiling: ")]),_c('div',{staticClass:"column"},[_c('Table',{attrs:{"sortable":"","input-data":_vm.tableData,"columns":['bod', 'verzekeraar'],"narrowed":""}})],1),_c('b-button',{attrs:{"expanded":"","type":"is-primary","icon-left":"gavel"}},[_vm._v(" Start nieuwe veiling ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }