var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"hero"},[_c('div',{staticClass:"hero-body"},[_c('h1',{staticClass:"title has-text-light"},[_vm._v(" Mijn dashboard ")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('Cardslot',{scopedSlots:_vm._u([{key:"iconheader",fn:function(){return [_c('b-icon',{attrs:{"icon":"shoe-prints","size":"is-large","type":"is-light"}})]},proxy:true},{key:"header",fn:function(){return [_c('div',{staticClass:"is-size-5"},[_vm._v(" Af te ronden aanvragen ")])]},proxy:true},{key:"content",fn:function(){return [(_vm.productsStatus)?_c('div',{staticClass:"columns is-multiline"},_vm._l((_vm.productsStatus.filter(function (product) {
                    return (
                      product.userStatus.eligibleToStartAuction ||
                      product.userStatus.status == 'Registration'
                    );
                  })),function(product){return _c('div',{key:product.pid,staticClass:"column is-6"},[_c('a',{on:{"click":function($event){return _vm.setPid(product.pid)}}},[_c('Cardslot',{staticClass:"mouseover leftborder",scopedSlots:_vm._u([{key:"iconheader",fn:function(){return [_c('b-icon',{attrs:{"type":"is-light","size":"is-small","icon":product.icon}})]},proxy:true},{key:"header",fn:function(){return [_vm._v(_vm._s(product.name))]},proxy:true},(product.userStatus.insuranceAdvice)?{key:"tagheader",fn:function(){return [_c('b-tag',{attrs:{"rounded":"","type":"is-link","size":"is-small"}},[_vm._v("a")])]},proxy:true}:null,{key:"content",fn:function(){return [_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12 has-text-centered"},[_c('b-button',{attrs:{"type":"is-primary","size":"is-small"}},[_vm._v(" Rond af ")])],1),_c('div',{staticClass:"column is-12"},[_c('b-progress',{attrs:{"value":product.requiredRegistrationSteps.length -
                                  (product.incompleteRegistrationSteps.length +
                                    1),"max":product.requiredRegistrationSteps.length,"type":"is-link","size":"is-small"}})],1)])]},proxy:true}],null,true)})],1)])}),0):_vm._e(),_c('b-button',{attrs:{"expanded":"","type":"is-primary","size":"is-small","icon-left":"sync-alt"},on:{"click":function($event){return _vm.requestplaidaccesstoken()}}},[_vm._v(" Lees mijn transacties uit en adviseer me! ")])]},proxy:true},{key:"footer",fn:function(){return [_vm._v(" "+_vm._s(_vm.requesttext)+" ")]},proxy:true}])})],1),_c('div',{staticClass:"column"},[_c('Cardslot',{scopedSlots:_vm._u([{key:"iconheader",fn:function(){return [_c('b-icon',{attrs:{"icon":"gavel","size":"is-large","type":"is-light"}})]},proxy:true},{key:"header",fn:function(){return [_c('div',{staticClass:"is-size-5"},[_vm._v(" Actieve veilingen ")])]},proxy:true},{key:"content",fn:function(){return [(_vm.productsStatus)?_c('div',{staticClass:"columns is-multiline"},_vm._l((_vm.productsStatus.filter(function (product) {
                    if (
                      Math.floor(
                        (new Date() - new Date(product.auction.auctionEnd)) /
                          1000 /
                          60 /
                          60 /
                          24
                      ) < 0
                    ) {
                      return product;
                    }
                  })),function(product){return _c('div',{key:product.pid,staticClass:"column is-12"},[_c('div',{staticClass:"box",class:{
                      opacity:
                        new Date() - new Date(product.auction.auctionEnd) > 0,
                    }},[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column has-text-centered"},[_c('h1',{staticClass:"subtitle has-text-light"},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('b-tag',{attrs:{"type":"is-link"}},[_vm._v(" 0 bieding(en) ")])],1),_c('div',{staticClass:"column is-flex has-text-centered"},[_c('auctionTimer',{attrs:{"time-left-prop":_vm.auctionTimeLeft(product.auction.auctionEnd),"timelimit":_vm.auctionBiddingTime(
                              product.auction.auctionEnd,
                              product.auction.crton
                            )}})],1)])])])}),0):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_vm._v(" "+_vm._s(_vm.auctiontext)+" ")]},proxy:true}])})],1),_c('div',{staticClass:"column"},[_c('Cardslot',{scopedSlots:_vm._u([{key:"iconheader",fn:function(){return [_c('b-icon',{attrs:{"icon":"building","size":"is-large","type":"is-light"}})]},proxy:true},{key:"header",fn:function(){return [_c('div',{staticClass:"is-size-5"},[_vm._v(" Lopende verzekeringen ")])]},proxy:true},{key:"tagheader",fn:function(){return undefined},proxy:true},{key:"content",fn:function(){return [(_vm.productsStatus)?_c('div',{staticClass:"columns is-multiline"},_vm._l((_vm.productsStatus.filter(function (product) {
                    return product.userStatus.status == 'Insurance in effect';
                  })),function(product,index){return _c('div',{key:product.pid,staticClass:"column is-6"},[_c('a',{on:{"click":function($event){return _vm.getSingleInsurance(product, index)}}},[_c('Cardslot',{staticClass:"mouseover leftborder",scopedSlots:_vm._u([{key:"iconheader",fn:function(){return [_c('b-icon',{attrs:{"type":"is-light","size":"is-small","icon":product.icon}})]},proxy:true},{key:"header",fn:function(){return [_vm._v(" "+_vm._s(product.name)+" ")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"\n                            columns\n                            is-vcentered\n                            has-text-centered\n                            is-multiline\n                          "},[_c('div',{staticClass:"column is-6 has-text-centered middle"},[_c('img',{staticStyle:{"width":"2rem"},attrs:{"src":require('@/assets/insurer_logo_' + index + '.png'),"alt":"Insurer logo"}})]),_c('p',{staticClass:"\n                              column\n                              is-6\n                              has-text-centered has-text-light\n                              is-italic\n                            "},[_vm._v(" €12.30 /m ")]),_c('div',{staticClass:"column is-12"},[_c('b-progress',{attrs:{"value":75,"type":"is-link","size":"is-small"}})],1)])]},proxy:true}],null,true)})],1)])}),0):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_vm._v(" "+_vm._s(_vm.insurancetext)+" ")]},proxy:true}])})],1)]),_c('p',{staticClass:"icon-text has-text-left"},[_c('b-tag',{attrs:{"rounded":"","size":"is-small","type":"is-link"}},[_vm._v(" a ")]),_c('span',{},[_vm._v(" = ons advies op basis van je situatie of transacties")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }