<template>
  <div class="column">
    <h1 class="title">
      <b-icon :icon="singleInsurance.icon" />
      {{ singleInsurance.name }}
    </h1>
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="box">
          <div class="columns">
            <div class="column">
              <div class="box">
                <h1 class="subtitle">
                  Verzekeraar:
                </h1>
                <div class="column has-text-centered">
                  <img
                    :src="require('@/assets/insurer_logo_' + index + '.png')"
                    alt="Insurer logo"
                    style="width: 8rem"
                  > 
                  <!-- <br>
                  {{ singleInsurance.auction.lowestBidder }} -->
                </div>
              </div>
            </div>
            <div class="column">
              <div class="box">
                <h1 class="subtitle">
                  Premie:
                </h1>
               
                <h1
                  class="title has-text-centered"
                >
                  {{ singleInsurance.auction.lowestBid }}
                  €12.30 
                </h1>
                <h1 class="subtitle has-text-centered">
                  per maand
                </h1>
              </div>
            </div>
          </div>
         
          <div class="columns is-vcentered">
            <div class="column is-1">
              22/03/2021
              <!-- {{ new Date(singleInsurance.auction.auctionEnd) }} -->
            </div><div class="column is-10">
              <b-progress
                :value="75"
                type="is-link"
                format="percent"
                show-value
              >
                Nog 27 dagen tot einde looptijd
              </b-progress>
            </div>
            <div class="column is-1">
              22/03/2022
            </div>
          </div>
        </div>
      </div>
      <!-- <div>Placeholder voor de single insurance component</div> <br>
    <div class="">
      Wat moet hier komen?
    </div>
    {{ singleInsurance }}
    <ul>
      <li>Premie</li>
      <li>Looptijd</li>

      <li>Veiling data</li>

      <li>Polis (?)</li>

      <li>Overzicht van dekking</li>

      <li>Schademelder (link)</li>
      <li>Start nieuwe veiling</li>
    </ul> -->
      <div class="column is-6">
        <div class="box">
          <h1
            v-if="singleInsurance.coverageChoices"
            class="subtitle"
          >
            Mijn keuze:
          </h1>
          <div
            v-if="singleInsurance.coverageChoices"
            class="columns is-multiline"
          >
            <!-- {{ singleInsurance.coverageOptions }} -->
            
            
            <div
              v-for="coverage in singleInsurance.coverageChoices.filter((coverage) => {
                return (
                  coverage.advice
                );
              })"
              :key="coverage.id"
              class="column"
            >
              <div class="box">
                <div class="column has-text-centered">
                  <b-tooltip :label="coverage.description">
                    <b-icon
                      size="is-large"
                      type="is-light"
                      :icon="coverage.icon"
                    />
                  </b-tooltip>
                </div>
                <div class="column has-text-centered has-text-light">
                  {{ coverage.name }}
                </div>
              </div>
            </div>
          </div>
          <h1
            v-if="singleInsurance.coverageOptions"
            class="subtitle"
          >
            Mijn dekking:
          </h1>
          <div
            v-if="singleInsurance.coverageOptions"
            class="columns is-multiline"
          >
            <!-- {{ singleInsurance.coverageOptions }} -->
            <div
              v-for="coverage in singleInsurance.coverageOptions.filter((coverage) => {
                return (
                  coverage.advice
                );
              })"
              :key="coverage.id"
              class="column"
            >
              <div class="box">
                <div class="column has-text-centered">
                  <b-tooltip :label="coverage.description">
                    <b-icon
                      size="is-large"
                      type="is-light"
                      :icon="coverage.icon"
                    />
                  </b-tooltip>
                </div>
                <div class="column has-text-centered has-text-light">
                  {{ coverage.name }}
                </div>
              </div>
            </div>
            <!-- <div class="column">
              <div class="box">
                <div class="column has-text-centered">
                  <b-tooltip label="coverage.description">
                    <b-icon
                      size="is-large"
                      type="is-light"
                      icon="bolt"
                    />
                  </b-tooltip>
                </div>
                <div class="column has-text-centered has-text-light">
                  Weerschade
                </div>
              </div>
            </div> -->
          </div>
         
          <!-- <div class="box">
            dekking
          </div>
          <div class="box">
            polis
          </div> -->
          
          <b-button
            expanded
            type="is-primary"
            icon-left="car-crash"
          >
            Meld schade
          </b-button>
        </div>
      </div>

      <div class="column is-6">
        <!-- <h1 class="title">
          Veiling
        </h1> -->
        <div class="box">
          <h1 class="subtitle">
            Veiling:
          </h1>
          <div class="column">
            <Table
              sortable
              :input-data="tableData"
              :columns="['bod', 'verzekeraar']"
              narrowed
            />
          </div>
          <b-button
            expanded
            type="is-primary"
            icon-left="gavel"
          >
            Start nieuwe veiling
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "../charts/table.vue"

export default {
  components: {
    Table
  },
  props: {
    singleInsurance: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tableData: [
        { offerId: "xyz", bod: 13, verzekeraar: "NN" },
        { offerId: "xdz", bod: 21, verzekeraar: "Centraal beheer" },
        { offerId: "xaz", bod: 14, verzekeraar: "OHRA" },
        // { offerId: "xyz", bod: 19, verzekeraar: "AON" },
        { offerId: "xdz", bod: 12.3, verzekeraar: "AEGON" },
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  height: 100%;
}
// .column {
//   height: 100%;
// }
</style>